
// Recipes
export const GET_RECIPES = 'GET_RECIPES';
export const ADD_RECIPE = 'ADD_RECIPE';
export const UPDATE_RECIPE = 'UPDATE_RECIPE';
export const RECIPES_LOADED = 'RECIPES_LOADED';
export const EXTERNAL_RECIPES_LOADED = 'EXTERNAL_RECIPES_LOADED';
export const CLEAR_RECIPES = 'CLEAR_RECIPES';
export const SET_RECIPE = 'SET_RECIPE';
export const DELETE_REMOTE_RECIPE = 'DELETE_REMOTE_RECIPE';
export const DELETE_LOCAL_RECIPE = 'DELETE_LOCAL_RECIPE';
export const LOAD_FEATURED_RECIPES = 'LOAD_FEATURED_RECIPES';
export const SET_FEATURED_RECIPES = 'SET_FEATURED_RECIPES';


// Authentication
export const LOGIN = 'LOGIN';
export const SIGNUP = 'SIGNUP';
export const GET_TOKEN = 'GET_TOKEN';
export const LOGOUT = 'LOGOUT';
export const AUTH_FAILED = 'AUTH_FAILED';
export const TOKEN_STORED = 'TOKEN_STORED';
export const VERIFY_EMAIL = 'VERIFY_EMAIL';
export const SET_VERIFIED = 'SET_VERIFIED';

export const CREATE_USER = 'CREATE_USER';
export const OPEN_MENU = 'OPEN_MENU';

export const WARNING = 'WARNING';
export const SET_USERNAME = 'SET_USERNAME';
export const TOGGLE_MENU = 'TOGGLE_MENU';
export const TOGGLE_LOADER = 'TOGGLE_LOADER';
export const WARNING_TOGGLE = 'WARNING_TOGGLE';
export const SET_REDIRECT = 'SET_REDIRECT';

export const GET_RECIPE = 'GET_RECIPE';

export const SEARCH = 'SEARCH';

export const SET_IMPORT_QUEUE = 'SET_IMPORT_QUEUE';
export const EXTERNAL_RECIPE_SEARCH = 'EXTERNAL_RECIPE_SEARCH';
export const PERFECT_RECIPE_SEARCH = 'PERFECT_RECIPE_SEARCH';
export const AUTOCOMPLETE = 'AUTOCOMPLETE';
export const SET_SEARCH_SUGGESTIONS = 'SET_SEARCH_SUGGESTIONS';

export const GRAPHQL = 'GRAPHQL';

export const IMPORT_RECIPE = 'IMPORT_RECIPE';
export const SET_DRAFT_RECIPE = 'SET_DRAFT_RECIPE';

export const UPDATE_USER_RECORD = 'UPDATE_USER_RECORD';
export const SEND_PASSWORD_RESET_EMAIL = 'SEND_PASSWORD_RESET_EMAIL ';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const RESEND_VERIFICATION = 'RESEND_VERIFICATION';

export const GET_RECIPE_TOKEN = 'GET_RECIPE_TOKEN';
export const CLAIM_RECIPE = 'CLAIM_RECIPE';

export const REQUEST_RECIPE = 'REQUEST_RECIPE';

export const SUBSCRIBE_TO_NEWSLETTER = 'SUBSCRIBE_TO_NEWSLETTER';

export const LOAD_INGREDIENTS = 'LOAD_INGREDIENTS';
export const INGREDIENT_DATA_LOADED = 'INGREDIENT_DATA_LOADED';
